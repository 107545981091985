<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1 add-company-document-page"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div
          v-if="editCompanyDocument && editCompanyDocument.name"
          class="component-title"
        >
          {{ editCompanyDocument.name }}
        </div>
        <template v-slot:right>
          <button @click="triggerSubmitForm">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <!-- Form start -->
    <VeeForm
      ref="addCompanyDocument"
      :key="formKey"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
      :initial-values="editCompanyDocument"
    >
      <!-- Active  -->
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="active"
                    type="checkbox"
                    v-model="isActive"
                    :checked="isActive"
                  />
                  <label for="active"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("documents", "edit-document", "active") }}
              </dd>
            </dl>
            <Field
              name="is_active"
              as="input"
              type="hidden"
              v-model="isActive"
            />
          </div>
        </li>

        <li class="clebex-item-section-item">
          <router-link
            :to="{
              name: 'r_company-documents-edit-select-language',
              params: $route.params
            }"
            class="clebex-item-content-wrapper has-link"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("global", "language", "language") }}
              </label>
              <div>
                <p class="text-label">{{ language }}</p>
              </div>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>

      <ul class="clebex-item-section">
        <!-- Name -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("documents", "edit-document", "name") }}
              </div>
              <Field
                name="name"
                as="input"
                type="text"
                :placeholder="
                  displayLabelName('documents', 'edit-document', 'name')
                "
                v-model="currentLocaleName"
              />
            </div>
          </div>
        </li>
        <!-- Publish date -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="name">
                {{
                  displayLabelName("documents", "edit-document", "publish-date")
                }}
              </label>
              <div @click="showDatePicker = !showDatePicker">
                <p class="text-label">{{ formattedPublishDate }}</p>
              </div>
              <div class="document-calendar-wrapper" v-if="showDatePicker">
                <select-date
                  :disablePreviousDays="true"
                  :selectDateFn="setPublishDate"
                  :show-date-picker="showDatePicker"
                  date-format="YYYY-MM-DD"
                  :display-date-format="globalDateFormat"
                  :selected-date="publishDate"
                />
              </div>
            </div>
          </div>
        </li>
        <!-- Version -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="version">
                {{ displayLabelName("documents", "edit-document", "version") }}
              </label>
              <Field
                id="version"
                name="version"
                as="input"
                type="text"
                v-model="version"
              />
            </div>
          </div>
        </li>
        <!-- Applications -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <router-link
            :to="{
              name: 'r_company-documents-edit-applications',
              params: $route.params
            }"
            class="clebex-item-content-wrapper has-link"
          >
            <div
              class="clebex-section-input"
              v-if="selectedApplications && selectedApplications.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName("documents", "edit-document", "applications")
                }}
              </div>
              <p class="text-label">
                {{
                  selectedApplications.map(item => `${item.name}`).join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName("documents", "edit-document", "applications")
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>

      <ul class="clebex-item-section">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">
                {{ displayLabelName("documents", "edit-document", "content") }}
              </label>
            </div>
          </div>
        </li>
      </ul>
      <ul v-if="!isPreview" class="clebex-item-section mail-flex">
        <li class="clebex-item-section-item mail-flex">
          <span class="error-message" v-if="errors.content">
            {{ errors.content }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <Field
                name="content"
                as="textarea"
                type="text"
                :placeholder="
                  displayLabelName('documents', 'edit-document', 'content')
                "
                v-model="templateContent"
              />
            </div>
          </div>
        </li>
      </ul>
      <ul
        class="clebex-item-section mail-flex"
        v-if="templateContent && isPreview"
      >
        <li class="clebex-item-section-item mail-flex">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input preview">
              <div v-html="templateContent"></div>
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" ref="submitAddForm" style="display: none"></button>
    </VeeForm>
    <!-- Form end -->

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: isPreview }"
              @click="isPreview = !isPreview"
            >
              {{ displayLabelName("documents", "edit-document", "preview") }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              @click="showResetAcceptanceDialog = true"
            >
              {{
                displayLabelName(
                  "documents",
                  "edit-document",
                  "reset-acceptances"
                )
              }}
            </button>
          </li>
        </ul>
      </nav>
    </template>

    <screen-modal
      v-if="showResetAcceptanceDialog"
      class="confirm-modal"
      type="success"
      :confirm-action="triggerResetAcceptances"
      :confirm-button-label="
        displayLabelName(
          'documents',
          'edit-document',
          'reset-acceptances-confirm-action'
        )
      "
      :cancelButtonLabel="
        displayLabelName(
          'documents',
          'edit-document',
          'reset-acceptances-cancel-action'
        )
      "
      :show="showResetAcceptanceDialog"
      @close="showResetAcceptanceDialog = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "documents",
            "edit-document",
            "reset-acceptances-title"
          )
        }}
      </h3>
      <br />
      <p class="text">
        {{
          displayLabelName(
            "documents",
            "edit-document",
            "reset-acceptances-action-question"
          )
        }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { getLang } from "@/services/http-service";
import { formatDate } from "@/services/helpers";

export default {
  name: "EditCompanyDocument",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDate: defineAsyncComponent(() =>
      import("@/components/global/SelectDate")
    )
  },
  props: {
    backLinkName: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      showResetAcceptanceDialog: false,
      initDone: false,
      showDatePicker: false,
      isPreview: false,
      isActive: false,
      formKey: 0,
      templateContent: "",
      currentLocaleName: "",
      version: ""
    };
  },
  watch: {
    "editCompanyDocument.version": {
      handler(value) {
        this.version = value;
      }
    },
    "editCompanyDocument.is_active": {
      handler(value) {
        this.isActive = value;
      }
    },
    selectedLanguage: {
      handler() {
        if (!this.initDone) {
          return;
        }
        this.setTranslations();
      }
    },
    contentTranslations: {
      handler() {
        if (this.initDone) {
          return;
        }
        this.initDone = true;
        this.setTranslations();
      }
    },
    currentLocaleName: {
      handler(value) {
        const newNameObject = {};
        newNameObject[this.selectedLanguage] = value;
        const newNames = { ...this.nameTranslations, ...newNameObject };
        this.$store.commit("companyDocuments/setNameTranslations", newNames, {
          root: true
        });
      }
    },
    templateContent: {
      handler(value) {
        const newContentObject = {};
        newContentObject[this.selectedLanguage] = value;
        const newContent = { ...this.contentTranslations, ...newContentObject };
        this.$store.commit(
          "companyDocuments/setContentTranslations",
          newContent,
          {
            root: true
          }
        );
      }
    },
    documentId: {
      handler(value) {
        if (value) {
          this.initDone = false;
          this.resetStoreState();
          this.getCompanyDocument(value).then(response => {
            this.setSelectedApplications(response.applications);
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat"]),
    ...mapState("applications", ["applications"]),
    ...mapState("companyDocuments", [
      "editCompanyDocument",
      "nameTranslations",
      "contentTranslations",
      "selectedApplications",
      "selectedLanguage",
      "publishDate"
    ]),
    ...mapState("language", ["languages"]),
    documentId() {
      const { documentId } = this.$route.params;
      return documentId;
    },
    formattedPublishDate() {
      if (!this.publishDate) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(this.publishDate, this.globalDateFormat);
    },
    language() {
      if (this.selectedLanguage && this.languages) {
        const lang = this.languages.data.find(
          l => l.locale === this.selectedLanguage
        );
        if (lang) {
          return lang.name;
        }
      }
      return "";
    },
    isActiveBind() {
      return this.isActive;
    },
    displaySelectedApplication() {
      if (!this.applications) {
        this.getApplications();
        return "";
      }
      const selectedApp = this.applications.find(
        app => app.id === this.selectedApplication
      );
      return selectedApp ? selectedApp.name : "";
    }
  },
  created() {
    const locale = getLang();
    this.$store.commit("companyDocuments/setSelectedLanguage", locale, {
      root: true
    });

    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });

    this.getCompanyDocument(this.documentId).then(response => {
      this.setSelectedApplications(response.applications);
    });
  },
  methods: {
    ...mapActions("applications", ["getApplications"]),
    ...mapActions("companyDocuments", [
      "getCompanyDocument",
      "putEditCompanyDocument",
      "setSelectedApplications"
    ]),
    ...mapActions("userAcceptance", ["deleteUserAcceptances"]),
    ...mapActions("searchFiltersCompanyDocuments", ["activateSearch"]),
    /**
     * Event callback methods
     */
    triggerResetAcceptances() {
      this.deleteUserAcceptances(this.documentId);
    },
    triggerSubmitForm() {
      const { submitAddForm } = this.$refs;
      submitAddForm.click();
    },
    /**
     * Set name and content translations on each change
     */
    setTranslations() {
      // content
      const selectedContent = this.contentTranslations[this.selectedLanguage];
      this.templateContent = selectedContent ? selectedContent : "";
      // name
      const selectedName = this.nameTranslations[this.selectedLanguage];
      this.currentLocaleName = selectedName ? selectedName : "";
    },
    /**
     * Prepare form data and submit
     * @param {*} values - form values
     */
    submitForm(values) {
      const payload = {
        application_ids: this.selectedApplications
          ? this.selectedApplications.map(app => app.id)
          : [],
        publish_date: this.publishDate,
        is_active: values.is_active,
        version: values.version,
        translations: this.prepareTranslations()
      };
      this.putEditCompanyDocument({ id: this.documentId, payload }).then(
        response => {
          // on success - activate search (with filters) & navigate back
          if (response) {
            this.activateSearch(true);
            this.$router.push({
              name: this.backLinkName,
              params: this.$route.params
            });
          }
        }
      );
    },
    /**
     * Prepare translations for form submission
     */

    prepareTranslations() {
      const translations = [];
      this.languages.data.forEach(lang => {
        const nameTranslation = this.nameTranslations[lang.locale];
        const contentTranslation = this.contentTranslations[lang.locale];

        translations.push({
          locale: lang.locale,
          attributes: {
            name: nameTranslation ? nameTranslation : null,
            content: contentTranslation ? contentTranslation : ""
          }
        });
      });
      return translations;
    },
    setPublishDate(value) {
      this.$store.commit("companyDocuments/setPublishDate", value, {
        root: true
      });
      this.showDatePicker = false;
    },
    resetStoreState() {
      const locale = getLang();
      this.$store.commit("companyDocuments/setSelectedLanguage", locale, {
        root: true
      });
    },
    unsetStoreState() {
      this.$store.commit("companyDocuments/setNameTranslations", [], {
        root: true
      });
      this.$store.commit("companyDocuments/setContentTranslations", [], {
        root: true
      });
      this.$store.commit("companyDocuments/setSelectedLanguage", null, {
        root: true
      });
      this.$store.commit("companyDocuments/setPublishDate", null, {
        root: true
      });
      this.$store.commit("companyDocuments/setEditCompanyDocument", null, {
        root: true
      });
    }
  },
  beforeUnmount() {
    this.unsetStoreState();
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
